import { ChangeEvent, InputHTMLAttributes } from "react";
import { useFormContext, FieldValues } from "react-hook-form";
import { FlexContainer } from "../СommonComponents";
import { InputField, InputErrorWrapper, InputWrapper } from "./InputComponents";
import { InputProps } from "./type/InputProps";

interface TextInputProps extends InputProps, InputHTMLAttributes<HTMLInputElement> {}

const TextInput = ({
   title,
   placeholder,
   fieldName,
   fieldError,
   resetServerErrors,
}: TextInputProps) => {
    const { register, clearErrors } = useFormContext<FieldValues>();

    const { onChange, ...otherProps } = register(fieldName);

    const fieldErrorMessage = fieldError?.message;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event);
        clearErrors(fieldName);
        resetServerErrors?.();
    };

    return (
        <FlexContainer $flexDirection="column">
            <InputWrapper>
                {title}
                <InputField
                    type="text"
                    placeholder={placeholder}
                    {...otherProps}
                    $isError={!!fieldErrorMessage}
                    onChange={handleChange}
                />
            </InputWrapper>
            {fieldErrorMessage && <InputErrorWrapper>{fieldErrorMessage}</InputErrorWrapper>}
        </FlexContainer>
    );
};

export default TextInput;
