import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { postFeedback } from "../../../../api/feedback/postFeedback";
import { Button } from "../../../ui/button/Button";
import TextInput from "../../../ui/input/TextInput";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { ErrorParagraphS } from "../../../ui/text/ParagraphComponents";
import { FlexContainer } from "../../../ui/СommonComponents";
import {
    FeedbackBlockContainer,
    FormEntity,
} from "./FeedbackFormSectionComponents";
import { feedbackValidation } from "./validation";
import FileInput from "../../../ui/input/FileInput";
import { AxiosError } from "axios";
import { parseServerError } from "../../../../utils/parseErrorsHelper";
import { DescriptionBlock, FormTitleSection, FormWrapper } from "../../../ui/formComponents/FormComponents";
import { formFeedbackMessage } from "../../../../utils/formFeedbackMessage";
import { MessagePayload } from "../../../../types/messagePayload";
import i18next from "i18next";


export type FieldName = "name" | "phone" | "email" | "position" | "about" | "files";

export type FeedbackType = {
    name: string,
    phone: string,
    email: string,
    position: string,
    about: string,
    files?: File[];
}

type FilePayload = {
    content: string, name: string
}

const emptyFeedbackFields: FeedbackType = {
    name: "",
    phone: "",
    email: "",
    position: "",
    about: "",
    files: [],
}

const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === "string") {
                //readAsDataURL adds prefix "data:[mime-type];base64," so wee need to remove it
                const base64String = result.split(",")[1];
                resolve(base64String);
            } else {
                reject(new Error("Failed to convert file to base64"));
            }
        }
        reader.onerror = error => reject(error);
    });
};

const getConvertedToBase64Files = async (files: File[]): Promise<FilePayload[]> => {
    return await Promise.all(files.map(async file => {
        const content = await convertFileToBase64(file);
        return {
            content,
            name: file.name
        };
    }));
};

type FormTextsVariant = "success" | "hasntSubmitYet";

const formFeedbackMessageWithFiles = async (data: FeedbackType): Promise<MessagePayload> => {
    const message = formFeedbackMessage(data);
    let convertedFiles: FilePayload[] = [];
    if (data.files) {
        convertedFiles = await getConvertedToBase64Files(data.files);
    }
    return {
        files: convertedFiles,
        ...message
    }
}

const FeedbackFormSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.feedbackForm"});

    const [isLoading, setIsLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState<string[]>([]);

    const validationScheme = feedbackValidation();

    const methods = useForm<FeedbackType>({
        mode: "onBlur",
        defaultValues: emptyFeedbackFields,
        resolver: yupResolver(validationScheme)
    });

    const resetServerErrors = () => {
        setServerErrors([]);
    };

    const onSubmit: SubmitHandler<FeedbackType> = async (data: FeedbackType) => {
        setIsLoading(true);

        const formedMessage = await formFeedbackMessageWithFiles(data);

        try {
            await postFeedback(formedMessage);
        } catch (error) {
            const axiosError = error as AxiosError;
            const parsedErrorsFromServer = parseServerError(axiosError, t);
            const errorsMessagesList = Object.values(parsedErrorsFromServer);
            setServerErrors(errorsMessagesList);
        } finally {
            setIsLoading(false);
        }
    };

    const formText: FormTextsVariant = methods.formState.isSubmitSuccessful ? "success" : "hasntSubmitYet";

    useEffect(() => {
        if (methods.formState.isSubmitSuccessful) {
           methods.reset(undefined, { keepIsSubmitSuccessful : true });
        }
        if (serverErrors.length) {
            methods.reset();
        }
    }, [methods.formState.submitCount]);

    useEffect(() => {
        methods.reset();
    }, [i18next.language]);

    return (
        <FeedbackBlockContainer>

            <FormTitleSection>
                <Header2>{t(`${formText}.header`)}</Header2>
                <DescriptionBlock>
                    {t(`${formText}.description`)}
                </DescriptionBlock>
            </FormTitleSection>

            <FormProvider {...methods}>
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormEntity>
                        <TextInput
                            title={t("fields.name")}
                            placeholder={t("placeholders.name")}
                            fieldName="name"
                            fieldError={methods.formState.errors.name}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.phone")}
                            placeholder={t("placeholders.phone")}
                            fieldName="phone"
                            fieldError={methods.formState.errors.phone}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.email")}
                            placeholder={t("placeholders.email")}
                            fieldName="email"
                            fieldError={methods.formState.errors.email}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.position")}
                            placeholder={t("placeholders.position")}
                            fieldName="position"
                            fieldError={methods.formState.errors.position}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.about")}
                            placeholder={t("placeholders.about")}
                            fieldName="about"
                            fieldError={methods.formState.errors.about}
                            resetServerErrors={resetServerErrors}
                        />
                        <FileInput
                            fieldName="files"
                            id="file-upload"
                            resetServerErrors={resetServerErrors}
                        />
                    </FormEntity>

                    <FlexContainer $flexDirection="column">
                        <Button
                            variant={methods.formState.isSubmitSuccessful ? "darkTertiary" : "lightSecondary"}
                            size="L"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!methods.formState.isDirty || !methods.formState.isValid || isLoading}
                        >
                            {t(`${formText}.button`)}
                        </Button>
                        {!!serverErrors.length && serverErrors.map((error) => (<ErrorParagraphS key={error}>
                            {error}
                        </ErrorParagraphS>))}
                    </FlexContainer>
                </FormWrapper>
            </FormProvider>

        </FeedbackBlockContainer>
    );
};

export default FeedbackFormSection;
