import * as yup from "yup";
import { RegExpressions } from "../../../../constants/regex";
import {
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_FIELD_LENGTH, MIN_FIELD_LENGTH
} from "../../../../constants/validationRules";
import { useTranslation } from "react-i18next";

export function contactsFormValidation() {
    const { t } = useTranslation("translation", {keyPrefix: "validation"});

    return yup
        .object({
            name: yup.string().trim().required(t("required")).min(MIN_FIELD_LENGTH, t(`minLength.${MIN_FIELD_LENGTH}`))
                .max(MAX_FIELD_LENGTH, t(`maxLength.${MAX_FIELD_LENGTH}`)),
            email: yup.string().trim().required(t("required"))
                .matches(RegExpressions.emailWithSpaces, t("email"))
                .max(MAX_FIELD_LENGTH, t(`maxLength.${MAX_FIELD_LENGTH}`)),
            message: yup.string().trim().required(t("required")).max(MAX_DESCRIPTION_FILED_LENGTH, t(`maxLength.${MAX_DESCRIPTION_FILED_LENGTH}`)),
        });
}
