import styled, { css } from "styled-components";

const baseInputStyles = css`
  padding: 11rem 8rem 11rem 0;
  outline: 0;
  border: none;
  
  background-color: transparent;

  font-size: 18rem;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.white};

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral_500};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    box-shadow: inset 0 0 0 32rem ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => `
      @media ${theme.media.medium} {
          font-size: 18px;
      }
  `};
`;

export const InputField = styled.input<{ $isError?: boolean }>`
  ${baseInputStyles};
    
  border-bottom: 2rem solid ${({ theme, $isError }) =>
          $isError ? theme.colors.error : theme.colors.neutral_400};
`;

export const TextAreaField = styled.textarea<{$isError?: boolean}>`
    ${baseInputStyles};

    border-bottom: 2rem solid ${({ theme, $isError }) =>
            $isError ? theme.colors.error : theme.colors.neutral_400};
    
    resize: none;
    width: 100%;
    box-sizing: border-box;
    line-height: 120%;
    min-height: 22rem;
    max-height: calc(22rem * 7);

    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            min-height: 22px;
            max-height: calc(22px * 7);
      }
  `};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;

  font-size: 14rem;
  font-weight: 400;
  line-height: 20rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
    
   max-height: 72rem; 

  ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 14px;
             line-height: 20px;
             max-height: 72px;
        }
    `};
`;

export const InputErrorWrapper = styled.div`
  margin-top: 4rem;

  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.error};
  line-height: 140%;

  ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 14px;
        }
    `};
`;

