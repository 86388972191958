import styled from "styled-components";
import { FormTitleSection } from "../../../ui/formComponents/FormComponents";

export const ContactsFormTitleSection = styled(FormTitleSection)`
    margin-bottom: 64rem;

    @media ${({ theme }) => theme.media.medium} {
        gap: 16px;
        margin-bottom: 48px;
    }
`;

export const ContactsFormContainer = styled.section`
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;

    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
`;

export const FormEntity = styled.div`
    display: flex;
    flex-direction: column;
    gap: 64rem;
    
    margin-bottom: 48rem;

    @media ${({ theme }) => theme.media.medium} {
        gap: 48px;
        margin-bottom: 48px;
    }
`;