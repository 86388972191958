import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { contactsFormValidation } from "./validation";
import { ContactsFormContainer, ContactsFormTitleSection, FormEntity } from "./ContactsFormComponents";
import { DescriptionBlock, FormWrapper } from "../../../ui/formComponents/FormComponents";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { parseServerError } from "../../../../utils/parseErrorsHelper";
import TextInput from "../../../ui/input/TextInput";
import { Button } from "../../../ui/button/Button";
import { ErrorParagraphS } from "../../../ui/text/ParagraphComponents";
import { FlexContainer } from "../../../ui/СommonComponents";
import { formFeedbackMessage } from "../../../../utils/formFeedbackMessage";
import { postFeedback } from "../../../../api/feedback/postFeedback";
import TextAreaInput from "../../../ui/input/TextAreaInput";

export type ContactsFormType = {
    name: string;
    email: string;
    message: string;
}

const emptyContactsFormFields: ContactsFormType = {
    name: "",
    email: "",
    message: "",
}

const ContactsFormSection = () => {
    const { t } = useTranslation("translation", {keyPrefix: "contacts"});
    const [isLoading, setIsLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState<string[]>([]);

    const validationScheme = contactsFormValidation();

    const methods = useForm<ContactsFormType>({
        mode: "onBlur",
        defaultValues: emptyContactsFormFields,
        resolver: yupResolver(validationScheme)
    });

    const resetServerErrors = () => {
        setServerErrors([]);
        methods.clearErrors();
    };

    const onSubmit: SubmitHandler<ContactsFormType> = async (data: ContactsFormType) => {
        setIsLoading(true);

        const formedMessage = formFeedbackMessage(data);

        try {
            await postFeedback(formedMessage);
            resetServerErrors();
            methods.reset();
        } catch (error) {
            const axiosError = error as AxiosError;
            const parsedErrorsFromServer = parseServerError(axiosError, t);
            const errorsMessagesList = Object.values(parsedErrorsFromServer);
            setServerErrors(errorsMessagesList);

            methods.setError("root", {
                type: "server",
                message: errorsMessagesList.join(", "),
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        methods.reset();
    }, [i18next.language]);

    return (
        <ContactsFormContainer>
            <ContactsFormTitleSection>
                <Header2>
                    <Trans
                        t={t}
                        i18nKey="header"
                        components={{ br: <br />}}
                    />
                </Header2>
                <DescriptionBlock>
                    {t("description")}
                </DescriptionBlock>
            </ContactsFormTitleSection>

            <FormProvider {...methods}>
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormEntity>
                        <TextInput
                            title={t("contactsForm.fields.name")}
                            placeholder={t("contactsForm.placeholders.name")}
                            fieldName="name"
                            fieldError={methods.formState.errors.name}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("contactsForm.fields.email")}
                            placeholder={t("contactsForm.placeholders.email")}
                            fieldName="email"
                            fieldError={methods.formState.errors.email}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextAreaInput
                            title={t("contactsForm.fields.message")}
                            placeholder={t("contactsForm.placeholders.message")}
                            fieldName="message"
                            fieldError={methods.formState.errors.message}
                            resetServerErrors={resetServerErrors}
                        />
                    </FormEntity>
                    <FlexContainer $flexDirection="column">
                        <Button
                            variant={methods.formState.isSubmitSuccessful ? "darkTertiary" : "lightSecondary"}
                            size="L"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!methods.formState.isDirty || !methods.formState.isValid || isLoading}
                        >
                            {t("contactsForm.buttons.send")}
                        </Button>
                        {!!serverErrors.length && serverErrors.map((error) => (
                            <ErrorParagraphS key={error}>
                                {error}
                            </ErrorParagraphS>
                        ))}
                    </FlexContainer>
                </FormWrapper>
            </FormProvider>
        </ContactsFormContainer>
    )
}

export default ContactsFormSection;