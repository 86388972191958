import styled from "styled-components";

export const FormAndAddressSectionWrapper = styled.section`
    padding: 128rem 40rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 80rem;

    @media ${({ theme }) => theme.media.medium} {
        flex-direction: column;
        padding: 48px 16px;
        gap: 64px;
    }
`;
