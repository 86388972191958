import { ChangeEvent, TextareaHTMLAttributes } from "react";
import { useFormContext, FieldValues } from "react-hook-form";
import { useTheme } from "styled-components";
import { InputErrorWrapper, InputWrapper, TextAreaField } from "./InputComponents";
import { FlexContainer } from "../СommonComponents";
import { InputProps } from "./type/InputProps";

interface TextAreaInputProps extends InputProps, TextareaHTMLAttributes<HTMLTextAreaElement> {}

const MAX_FIELD_ROWS = 7;

const TextAreaInput = ({
   title,
   placeholder,
   fieldName,
   fieldError,
   resetServerErrors = () => {},
}: TextAreaInputProps) => {
    const { register, clearErrors } = useFormContext<FieldValues>();
    const theme = useTheme();

    const { onChange, ...otherProps } = register(fieldName);

    const fieldErrorMessage = fieldError?.message;

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);

        textarea.style.height = "auto";

        const isMobile = window.matchMedia(theme.media.medium).matches;
        const calculatedScrollHeight = Math.min(textarea.scrollHeight, lineHeight * MAX_FIELD_ROWS);

        textarea.style.height = isMobile ? `${calculatedScrollHeight}rem` : `${calculatedScrollHeight}px`;

        onChange(event);
        clearErrors(fieldName);
        resetServerErrors?.();
    };

    return (
        <FlexContainer $flexDirection="column">
            <InputWrapper>
                {title}
                <TextAreaField
                    placeholder={placeholder}
                    {...otherProps}
                    $isError={!!fieldErrorMessage}
                    onChange={handleChange}
                    rows={1}
                />
            </InputWrapper>
            {fieldErrorMessage && <InputErrorWrapper>{fieldErrorMessage}</InputErrorWrapper>}
        </FlexContainer>
    );
};

export default TextAreaInput;
