type PageNameType = "main" | "vacancies" | "culture" | "contacts";

export const MenuPages: Array<PageNameType> = ["main", "culture", "contacts"];

const RoutesMap: Record<PageNameType, string> = {
    main: "/",
    vacancies: "/vacancies",
    culture: "/culture",
    contacts: "/contacts"
}

export  default RoutesMap;
