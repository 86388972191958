import styled from "styled-components";
import { ReactComponent as CompanyNameOldIcon } from "../../../../resources/icon/company-name-old.svg";

export const FooterContainer = styled.footer`
    background-color: ${({ theme }) => theme.colors.black};
    padding: 128rem 40rem 64rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    

    @media ${({ theme }) => theme.media.large} {
        padding: 128px 40px 64px;
    }

    @media ${({ theme }) => theme.media.medium} {
        padding: 48px 16px;
        align-items: flex-start;
    }

    @media ${({ theme }) => theme.media.small} {
        justify-content: center;
    }
`;

export const CompanyNameIcon = styled(CompanyNameOldIcon)`
    display: flex;
    align-items: center;
    width: 100%;

    @media ${({ theme }) => theme.media.medium} {
        width: 343px;
        height: auto;
        align-items: flex-start;
    }

    @media ${({ theme }) => theme.media.small} {
        align-self: center;
        justify-content: center;
    }
`;